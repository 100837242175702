import React from "react";
import {Navigate} from 'react-router-dom';
// import axios from 'axios';
import Menu from "../components/menu/Menu";
import ListadoUsuarios from "../components/listado-usuarios/ListadoUsuarios";
import {Container} from 'react-bootstrap';
import Verify from "../components/verify/Verify"
// import { trackPromise } from 'react-promise-tracker';

const Productos = () => {


    if(!sessionStorage.getItem('userData')){
        return <Navigate to="/"  />;
    }
    else{
        Verify()
    }
    
    let ud = sessionStorage.getItem('userData');
    let userData = JSON.parse(ud);

    if(userData.jerarquia === 2){
        return(
            <>
                <Menu />
                <Container>
                    <ListadoUsuarios />
                </Container>
            </>
        )
    }
    else
        return(<>
             <Menu />
             <Container>
                <h1 className="mt-4">No tiene permiso para ver este recurso</h1>
             </Container>
        </>
        )
};

export default Productos;
