import { useState, useEffect, useRef } from 'react';
import { Container, Table, Form, Alert, Row, Col } from 'react-bootstrap';
import { FaEdit, FaThumbsUp, FaShare } from 'react-icons/fa';
import { TiDelete } from "react-icons/ti";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import "./invitados.css";
import { FaRegClone, FaRegFile, FaQrcode } from "react-icons/fa6";
import { useDownloadExcel } from 'react-export-table-to-excel';
// import FileUpload from '../excel-upload/FileUpload';

function TableInvitados({ id }) {

    const [show, setShow]                                       = useState(false);
    const [showDelete, setShowDelete]                           = useState(false);
    const [showAdd, setShowAdd]                                 = useState(false);
    const [showAddMultiple, setShowAddMultiple]                 = useState(false);
    const [showQr, setShowQr]                                   = useState(false);
    const [showPass, setShowPass]                               = useState(false);
    const [showResend, setShowResend]                           = useState(false);
    const [displayQr, setDisplayQr]                             = useState(false);
    const [deleteId, setDeleteId]                               = useState(false);
    const [resendId, setResendId]                               = useState(false);
    const [passId, setPassId ]                                  = useState(false);
    const [items, setItems]                                     = useState([]);
    const [showAlert, setShowAlert]                             = useState(false);
    const [showAlertDelete, setShowAlertDelete]                 = useState(false);
  
    // ---------- nuevo invitados ------------
    const [selectEventos, setSelectEventos]                     = useState([]);
    const [selectHorarios, setSelectHorarios]                   = useState(["NA"]);
    const [inputNombre, setInputNombre]                         = useState("");
    const [inputApellidoP, setInputApellidoP]                   = useState("");
    const [inputApellidoM, setInputApellidoM]                   = useState("");
    const [inputEvento, setInputEvento]                         = useState("");
    const [inputEmail, setInputEmail]                           = useState("");
    const [inputHorario, setInputHorario]                       = useState("");
    const [inputClave, setInputClave]                           = useState("");
    const [showHorariosDiv, setShowHorariosDiv]                 = useState(false);
    const [statusBtnSubmit, setStatusBtnSubmit]                 = useState(false);
    const [statusBtnSubmitMultiple, setStatusBtnSubmitMultiple] = useState(false);

    // ----- edit invitados -----------
    const [inputIdEdit, setInputIdEdit]                         = useState("");
    const [inputNombreEdit, setInputNombreEdit]                 = useState("");
    const [inputPaternoEdit, setInputPaternoEdit]               = useState("");
    const [inputMaternoEdit, setInputMaternoEdit]               = useState("");
    const [inputEventoEdit, setInputEventoEdit]                 = useState("");
    const [inputClaveEdit, setInputClaveEdit]                   = useState("");
    const [inputEmailEdit, setInputEmailEdit]                   = useState("");
    const [inputHorarioEdit, setInputHorarioEdit]               = useState("");
    const [inputStatusEdit, setInputStatusEdit]                 = useState("");
    const [showHorariosDivEdit, setShowHorariosDivEdit]         = useState(false);
    const [selectHorariosEdit, setSelectHorariosEdit]           = useState([]);
    const [disableEditBtn, setDisableEditBtn]                   = useState("");

    const tableRef                  = useRef(null)

    const handleClose               = () => setShow(false);
    const handleCloseDelete         = () => setShowDelete(false);
    const handleCloseQr             = () => setShowQr(false);
    const handleClosePass           = () => setShowPass(false);
    const handleCloseResend         = () => setShowResend(false)
    const handleCloseAdd            = () => setShowAdd(false);
    const handleCloseAddMultiple    = () => setShowAddMultiple(false);

    //------------------------- mapas -------------------------
    //const position = {lat: 53.54992, lng: 10.00678};
      
    const handleShow = (item_id) => {
        setShow(true);
    
        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 5000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
        
            instance.get('../api/get-invitado/'+item_id)
            .then(response => {
                setInputIdEdit(response.data.data[0].id)
                setInputNombreEdit(response.data.data[0].nombre)
                setInputPaternoEdit(response.data.data[0].apellido_paterno)
                setInputMaternoEdit(response.data.data[0].apellido_materno)
                setInputClaveEdit(response.data.data[0].clave_invitado)
                setInputEmailEdit(response.data.data[0].email)
                setInputEventoEdit(response.data.data[0].id_evento)
                setInputHorarioEdit(response.data.data[0].id_horario)
                setInputStatusEdit(response.data.data[0].status)

                if(response.data.data[0].id_horario){
                    setShowHorariosDivEdit(true)
                }
                else{
                    setShowHorariosDivEdit(false)
                }

                instance.get('../api/get-horarios/'+response.data.data[0].id_evento)
                .then(responsex => {
                    setSelectHorariosEdit(responsex.data.data)
                })
            })

            

    }

    const handleShowAdd = async () => {

        randomPassword();
        setShowAdd(true);
    }

    const handleShowAddMultiple = async () => {

        setShowAddMultiple(true);
    }

    const handleShowDelete = (item_id) => {
        setShowDelete(true);
        setDeleteId(item_id)
    }

    const handleDelete = () => {
        deleteInvitado(deleteId)
    }

    const handleShowPass = (id) => {
        setPassId(id)
        setShowPass(true)
    }

    const handleShowResend = (id) => {
        setResendId(id)
        setShowResend(true)
    }

    const getDataHorarios = async (id) => {
            
        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 10000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
        
        //trackPromise(

       

        await instance.get('../api/get-evento/'+id)
        .then(responsex => {
            if(responsex.data.data[0]){
                if(responsex.data.data[0].horarios_especificos>0){
                    setShowHorariosDiv(true)
                }
                else{
                    setShowHorariosDiv(false)
                }
            }
            else{
                setShowHorariosDiv(false)
            }
            
        })

            await instance.get('../api/get-horarios/'+id)
            .then(response => {
                setSelectHorarios(response.data.data)
            })

        //)
    }

    const getDataHorariosEdit = async (id) => {
            
        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 10000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
        
        //trackPromise(

        await instance.get('../api/get-evento/'+id)
        .then(responsex => {
            if(responsex.data.data[0]){
                if(responsex.data.data[0].horarios_especificos>0){
                    setShowHorariosDivEdit(true)
                }
                else{
                    setShowHorariosDivEdit(false)
                }
            }
            else{
                setShowHorariosDivEdit(false)
            }
            
        })

            await instance.get('../api/get-horarios/'+id)
            .then(response => {
                console.log(response.data.data)
                setSelectHorariosEdit(response.data.data)
            })

        //)
    }

    const getData= () => {

        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 10000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
            
            instance.get('../api/get-eventos')
            .then(response2 => {
                setSelectEventos(response2.data)
            })

            if(id){
                instance.get('../api/get-invitados/'+id)
                .then(response => {
                    setItems(response.data)
                })
            }
            else{
                instance.get('../api/get-invitados')
                .then(response => {
                    setItems(response.data)
                })
            }
            

    }

    useEffect(()=>{
        getData()
    },[])

    const generateQR = async (token) => {

        //display Modal
        setShowQr(true)

        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 10000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
        
        //trackPromise(
            await instance.get('../api/get-qr/'+token)
            .then(response => {
                //console.log(response.data.data)
                setDisplayQr(response.data.data)
            })
    }

    const deleteInvitado=(id)=>{
            
        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 5000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
        
        trackPromise(
            instance.delete('../api/delete-invitado/'+id)
            .then(response => {
                //console.log(response.status)
                if(response.status === 200){
                    setShowDelete(false);
                    setDeleteId("")
                    setShowAlertDelete(true)
                    getData()
                }
            })
        );
    }

    const  ConfirmResend = () => {

        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 5000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
        
        trackPromise(
            instance.get('../api/re-send/'+resendId)
            .then(response => {
                //console.log(response.status)
                if(response.status === 200){
                    setShowResend(false);
                    setResendId("")
                    getData();
                }
            })
        );
    }

    const  ConfirmAsPass = () => {

        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 5000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
        
        trackPromise(
            instance.get('../api/update-code/'+passId)
            .then(response => {
                //console.log(response.status)
                if(response.status === 200){
                    setShowPass(false);
                    setPassId("")
                    getData()
                }
            })
        );
    }

    const handleSubmitEdit = async (event) => {

        setDisableEditBtn(true)
        event.preventDefault();
       
    
        if( inputNombreEdit && inputPaternoEdit && inputMaternoEdit && inputClaveEdit && inputEventoEdit && inputEmailEdit){
          
          var data_send = {
            "nombre": inputNombreEdit,
            "apellido_paterno": inputPaternoEdit,
            "apellido_materno": inputMaternoEdit,
            "clave_invitado": inputClaveEdit,
            "email": inputEmailEdit,
            "id_evento": inputEventoEdit,
            "id_horario": inputHorarioEdit,
          }
    
          let ud = sessionStorage.getItem('userData');
          let userData = JSON.parse(ud);
    
          const instance = axios.create({
                  baseURL: '',
                  timeout: 5000,
                  headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                  });
            
            instance.put('../api/update-invitado/'+inputIdEdit, data_send)
            .then(response => {
                //console.log(response)
                if(response.data.status === "200"){
                    // setInputNombreEdit("");
                    // setInputExistenciasEdit("");
                    setDisableEditBtn(false)
                    setShow(false)
                    setShowAlert(true)
                    getData()
                }
            })
            .catch(function (error) {
                if(error.response.status === "401"){
                console.log(error)
                
                }
            });
    
    
        }
        else{
          //return error
          alert("Faltan datos"+inputNombreEdit);
          setDisableEditBtn(false)
        }
        
    }

    const handleChangeEvent = async (id) => {
        //updateEvent(id);
        window.location.href = "../../invitados/"+id;
        //return <Redirect to="invitados/" />;
    }

    const handleSubmit = async (event) =>{

        event.preventDefault();
        setStatusBtnSubmit(true)

        if(showHorariosDiv){
            
            if(inputNombre && inputApellidoP && inputApellidoM && inputClave && inputEvento && inputHorario){
                
                var data_send2 = {
                    "nombre": inputNombre,
                    "apellido_paterno": inputApellidoP,
                    "apellido_materno": inputApellidoM,
                    "clave_invitado": inputClave,
                    "email": inputEmail,
                    "id_evento": inputEvento,
                    "id_horario": inputHorario,
                    "status": 0
                }
            
                let ud = sessionStorage.getItem('userData');
                let userData = JSON.parse(ud);
            
                const instance = axios.create({
                        baseURL: '',
                        timeout: 12000,
                        headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                        });
                    
                instance.post('../api/add-invitado', data_send2)
                .then(response => {
        
                    if(response.data.status === "200"){
                        setStatusBtnSubmit(false)
                        setInputNombre("");
                        setInputApellidoP("");
                        setInputApellidoM("");
                        setInputClave("");
                        setInputEvento("");
                        setInputEmail("");
                        setInputHorario("");
                        setShowAdd(false)
                        setShowAlert(true)
                        getData()
                    }
                    else{
                        alert("No se tiene capacidad en este horario")
                        setStatusBtnSubmit(false)
                    }
                })
                .catch(function (error) {
                
                        if(error.response.status === "401"){
                        console.log("se venció el token, please update it")
                        window.location.replace("./refresh-token");
                        
                        }
                });

            }
            else
                alert("Error #3435, [ Faltan datos ]"+inputNombre + inputClave + inputEvento);
        }
        else{
            if(inputNombre && inputClave && inputEvento ){
                
                var data_send = {
                    "nombre": inputNombre,
                    "apellido_paterno": inputApellidoP,
                    "apellido_materno": inputApellidoM,
                    "email": inputEmail,
                    "clave_invitado": inputClave,
                    "id_evento": inputEvento,
                    "status": 0
                }
            
                let ud = sessionStorage.getItem('userData');
                let userData = JSON.parse(ud);
            
                const instance = axios.create({
                        baseURL: '',
                        timeout: 12000,
                        headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                        });
                    
                instance.post('../api/add-invitado', data_send)
                .then(response => {
                    
                    if(response.data.status === "200"){
                        setInputNombre("");
                        setInputApellidoP("");
                        setInputApellidoM("");
                        setInputClave("");
                        setInputEvento("");
                        setInputHorario("");
                        setStatusBtnSubmit(false)
                        setShowAdd(false)
                        setShowAlert(true)
                        setTimeout(() => {
                            console.log("Waiting...");
                          }, 2000);
                        getData()
                    }
                    else{
                        alert("El horario seleccionado ya no tiene capacidad")
                    }
                })
                .catch(function (error) {
                    
                    console.log(error);

                        if(error.response.status === "401"){
                        console.log("se venció el token, please update it")
                        window.location.replace("./refresh-token");
                        
                        }
                });

            }
            else{
                alert("Error #3435, [ Faltan datos ]"+inputNombre + inputClave + inputEvento);
                setStatusBtnSubmit(false)
            }
        }
    }

    const randomPassword = async () => {
       var pwd = Math.random().toString(36).slice(2) + Math.random().toString(36).toUpperCase().slice(2);
       setInputClave(pwd)
    }

    var newFile = Date.now();

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: newFile+"_user-info",
        sheet: 'data'
    })

    const FileUpload = () => {
        const [selectedFile, setSelectedFile]   = useState(null);
        const [idEvento, setIdEvento]           = useState();

        // Handle file selection
        const handleFileChange = (e) => {
          setSelectedFile(e.target.files[0]);
        };
      
        // Handle file upload and request to backend
        const handleFileUpload = async () => {

            setStatusBtnSubmitMultiple(true)

          if (!selectedFile) {
            alert("Por favor seleccione un archivo");
            return;
          }
          const formData = new FormData();
          formData.append('file', selectedFile);
          formData.append('id_evento', idEvento);
          
          let ud = sessionStorage.getItem('userData');
          let userData = JSON.parse(ud);

          try {
            // Choose the right endpoint based on file type
            const endpoint = 'upload-xlsx';
            const response = await axios.post(`../api/${endpoint}`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer '+userData.access_token
              },
            });
            console.log()
            // setParsedData(response.data); // Store the parsed data in state
            // aqui van cosas -------
            if(response.data.status === 500){
                alert("Archivo con formato incorrecto, por favor descargue el archivo de referencia.")
                setStatusBtnSubmitMultiple(false)
            }
            else{
                alert(JSON.stringify(response.data));
                setShowAlert(true)
                setShowAddMultiple(false)
                setStatusBtnSubmitMultiple(false)
                setTimeout(() => {
                    console.log("Waiting...");
                    getData()
                }, 2000);
            }
            

          } catch (error) {
            // console.error("Error uploading file:", error);
            // setShowAddMultiple(false)
            alert("Archivo con formato incorrecto, por favor descargue el archivo de referencia.")
            setStatusBtnSubmitMultiple(false)
          }
        };
      

          return (
              <div>
                <Form>
                    <Form.Group className="mb-3" controlId="formBasicEvento">
                        <Form.Label>Evento</Form.Label>
                        <Form.Select aria-label="Seleccione..." onChange={(e) => { setIdEvento(e.target.value); }}>
                             <option>Seleccione...</option>
                            {
                                selectEventos.map((item)=> 
                                    (<option value={item.ide}>{item.nombre}</option>))
                            }
                        </Form.Select>
                    </Form.Group>
                    {
                        showHorariosDiv && (
                            <Form.Group className="mb-3" controlId="formBasicHorario">
                                <Form.Label>Horario</Form.Label>
                                <Form.Select aria-label="Seleccione..." onChange={(e) => setInputHorario(e.target.value)}>
                                    <option>Seleccione...</option>
                                    {
                                        selectHorarios.map((item)=> {
                                            if(item.asignados<item.capacidad) {
                                                return <option value={item.idh}>{item.hora_inicial} - {item.hora_fin}</option>
                                            }
                                            else{
                                                return <option value={item.idh} disabled>{item.hora_inicial} - {item.hora_fin}</option>
                                            }
                                        })
                                    }
                                </Form.Select>
                            </Form.Group>
                        )
                    }

                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Archivo excel</Form.Label>
                        <Form.Control type="file" onChange={handleFileChange} />
                    </Form.Group>
                    
                    <Row>
                        <Col>
                            <Button className='btn_buscar btn_right' onClick={handleFileUpload} disabled={statusBtnSubmitMultiple}>Guardar</Button>
                        </Col>
                    </Row>
                    
                    </Form>
              </div>
          );
      };

    function formatDateTime(str){

        str = str.toString()
        str = str.padStart(2, "0")

        return str
    }
    
    return (
        <Container Fluid>
        <Row className='mb-4'>
            <Col><h1 className="mt-4">Invitados</h1></Col>
            <Col className='iconos_div'><FaRegFile className="btn-add-nuevo" onClick={() => handleShowAdd()}  /></Col>
            <Col className='iconos_div'><FaRegClone  className="btn-add-nuevo" onClick={() => handleShowAddMultiple()} /></Col>
        </Row>

        <Alert className="mt-4" key="success" variant="success" show={showAlert} onClose={() => setShowAlert(false)} dismissible>
            El invitado se guardó exitosamente
        </Alert>

        <Alert className="mt-4" key="danger" variant="danger" show={showAlertDelete} onClose={() => setShowAlertDelete(false)} dismissible>
            El invitado se eliminó exitosamente
        </Alert>
            <Container fluid>
                <Row className="justify-content-md-center mb-4">
                    <Col lg={4}>
                        <Form.Select aria-label="Default select example" onChange={e => handleChangeEvent(e.target.value)}>
                            <option>Seleccione un evento...</option>
                            {
                                selectEventos.map((item)=> 
                                        (<>
                                        {
                                        id === item.ide ?  (<option value={item.ide} selected>{item.nombre}</option>) :  (<option value={item.ide}>{item.nombre}</option>)
                                        }
                                        </>)
                                    )
                            }
                        </Form.Select>
                    </Col>
                    <Col className='d-grid'>
                        <Button onClick={onDownload} className='btn_buscar btn_right'>Exportar</Button>
                    </Col>
                </Row>
            
        
            {
                id && (
                <Table striped bordered hover responsive ref={tableRef}>
                    <thead>
                        <tr>
                            <th className='encabezado_tabla'>#</th>
                            <th className='encabezado_tabla'>Nombre</th>
                            <th className='encabezado_tabla'>Apellido Paterno</th>
                            <th className='encabezado_tabla'>Apellido Materno</th>
                            <th className='encabezado_tabla'>Email</th>
                            <th className='encabezado_tabla'>Evento</th>
                            <th className='encabezado_tabla'>Fecha evento</th>
                            <th className='encabezado_tabla'>Venue</th>
                            <th className='encabezado_tabla'>Status</th>
                            <th className='encabezado_tabla'>Fecha acceso</th>
                            <th className='encabezado_tabla'>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        items && items.length>0 && items.map(function(item, index){
                            
                            var fecha_ok = item.fecha_evento

                            var today           = new Date(fecha_ok);
                            var year            = today.getFullYear();
                            var mes             = formatDateTime(today.getMonth()+1);
                            var dia             = formatDateTime(today.getDate());
                            var fecha           = dia+"-"+mes+"-"+year;
                            var fecha_acceso    = ""

                            if(item.fecha_acceso != null){
                                var today2           = new Date(item.fecha_acceso);
                                var year2            = today2.getFullYear();
                                var mes2             = formatDateTime(today2.getMonth()+1);
                                var dia2             = formatDateTime(today2.getDate());
                                var hora2            = formatDateTime(today2.getHours());
                                var minutos2         = formatDateTime(today2.getMinutes());
                                var segundos2        = formatDateTime(today2.getSeconds());
                                fecha_acceso         = dia2+"-"+mes2+"-"+year2+" "+hora2+":"+minutos2+":"+segundos2;

                            }
                            else{
                                fecha_acceso = ""
                            }

                            var txt_status = ""

                            if(item.i_status === 1)
                                txt_status = "Utilizado"
                            else
                                txt_status = "Activo"

                            return (
                            <tr>
                                <td className='data_tabla'>{index}</td>
                                <td className='data_tabla'>{item.nombre}</td>
                                <td className='data_tabla'>{item.apellido_paterno}</td>
                                <td className='data_tabla'>{item.apellido_materno}</td>
                                <td className='data_tabla'>{item.email}</td>
                                <td className='data_tabla'>{item.nombree}</td>
                                <td className='data_tabla'>{fecha}</td>
                                <td className='data_tabla'>{item.nombre_venue}</td>
                                <td className='data_tabla'>{txt_status}</td>
                                <td className='data_tabla'>{fecha_acceso}</td>
                                <td className='data_tabla'><FaEdit className='icono_listado' onClick={() => handleShow(item.idi)}  /> <TiDelete className='icono_listado' onClick={() => handleShowDelete(item.idi)} /> <FaQrcode className='icono_listado' onClick={() => generateQR(item.clave_invitado)}  /> <FaThumbsUp className='icono_listado' onClick={() => handleShowPass(item.clave_invitado)}  />  <FaShare className='icono_listado' onClick={() => handleShowResend(item.clave_invitado)}  /> </td>
                            </tr>
                            )
                        }
                        )
                    }
                    </tbody>
                </Table>
                    )
            }

        </Container>

        <Modal show={showAdd} onHide={handleCloseAdd}>
                <Modal.Header closeButton>
                <Modal.Title>Agregar Invitado</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label htmlFor="input_nombre">Nombre</Form.Label>
                        <Form.Control 
                        id="input_nombre"
                        type="text" 
                        placeholder="Nombre"
                        // defaultValue={}
                        onKeyUp={(e) => setInputNombre(e.target.value)}
                        required />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label htmlFor="input_paterno">Apellido Paterno</Form.Label>
                        <Form.Control 
                        id="input_paterno"
                        type="text" 
                        placeholder="Apellido Paterno"
                        // defaultValue={}
                        onKeyUp={(e) => setInputApellidoP(e.target.value)}
                        required />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label htmlFor="input_materno">Apellido Materno</Form.Label>
                        <Form.Control 
                        id="input_materno"
                        type="text" 
                        placeholder="Apellido Materno"
                        // defaultValue={}
                        onKeyUp={(e) => setInputApellidoM(e.target.value)}
                        required />
                    </Form.Group>
                    
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label htmlFor="input_nombre">Evento</Form.Label>
                        <Form.Select aria-label="Seleccione..." onChange={(e) => { setInputEvento(e.target.value); getDataHorarios(e.target.value); }}>
                             <option>Seleccione...</option>
                            {
                                selectEventos.map((item)=> 
                                    (<option value={item.ide}>{item.nombre}</option>))
                            }
                        </Form.Select>
                    </Form.Group>
                    {
                        showHorariosDiv && (
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label htmlFor="input_nombre">Horario</Form.Label>
                                <Form.Select aria-label="Seleccione..." onChange={(e) => setInputHorario(e.target.value)}>
                                    <option>Seleccione...</option>
                                    {
                                        selectHorarios.map((item)=> {
                                            if(item.asignados<item.capacidad) {
                                                return <option value={item.idh}>{item.hora_inicial} - {item.hora_fin}</option>
                                            }
                                            else{
                                                return <option value={item.idh} disabled>{item.hora_inicial} - {item.hora_fin}</option>
                                            }
                                        })
                                    }
                                </Form.Select>
                            </Form.Group>
                        )
                    }

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" onKeyUp={(e) => setInputEmail(e.target.value)} placeholder="Email" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Clave invitado</Form.Label>
                        <Form.Control type="text" defaultValue={inputClave} onKeyUp={(e) => setInputClave(e.target.value)} placeholder="Clave invitado" />
                    </Form.Group>

                    <Button className='btn_buscar btn_right' type="submit" onClick={(handleSubmit)} disabled={statusBtnSubmit}>
                        Guardar
                    </Button>
                
                </Modal.Body>
        </Modal>

        <Modal size="lg" show={showAddMultiple} onHide={handleCloseAddMultiple}>
                <Modal.Header closeButton>
                <Modal.Title>Agregar Invitado Multiple <span className='archivo_muestra'><a href="files/schema.xlsx">Descargar archivo muestra</a></span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FileUpload />
                </Modal.Body>
        </Modal>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Editar Invitado</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    inputStatusEdit<1 ? (
                        <>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label htmlFor="input_nombre">Nombre</Form.Label>
                            <Form.Control 
                            id="input_nombre"
                            type="text" 
                            placeholder="Nombre completo"
                            defaultValue={inputNombreEdit}
                            onKeyUp={(e) => setInputNombreEdit(e.target.value)}
                            required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label htmlFor="input_paterno">Apellido Paterno</Form.Label>
                            <Form.Control 
                            id="input_paterno"
                            type="text" 
                            placeholder="Apellido Paterno"
                            defaultValue={inputPaternoEdit}
                            onKeyUp={(e) => setInputPaternoEdit(e.target.value)}
                            required />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label htmlFor="input_materno">Apellido Materno</Form.Label>
                            <Form.Control 
                            id="input_materno"
                            type="text" 
                            placeholder="Apellido Materno"
                            defaultValue={inputMaternoEdit}
                            onKeyUp={(e) => setInputMaternoEdit(e.target.value)}
                            required />
                        </Form.Group>
                        
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label htmlFor="input_nombre">Evento</Form.Label>
                            <Form.Select aria-label="Seleccione..." value={inputEventoEdit} onChange={(e) => {setInputEventoEdit(e.target.value);  getDataHorariosEdit(e.target.value); }  }>
                                <option>Seleccione...</option>
                                {
                                    selectEventos.map((item)=> 
                                        (<option value={item.ide}>{item.nombre}</option>))
                                }
                            </Form.Select>
                        </Form.Group>

                        {
                        showHorariosDivEdit && (
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label htmlFor="input_nombre">Horario</Form.Label>
                                <Form.Select aria-label="Seleccione..." value={inputHorarioEdit} onChange={(e) => setInputHorarioEdit(e.target.value)}>
                                    <option>Seleccione...</option>
                                    {
                                        selectHorariosEdit.map((item)=>
                                            (<option value={item.id}>{item.hora_inicial} - {item.hora_fin}</option>))
                                    }
                                </Form.Select>
                            </Form.Group>
                        )
                    }

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Clave invitado</Form.Label>
                            <Form.Control type="text" defaultValue={inputClaveEdit} onKeyUp={(e) => setInputClaveEdit(e.target.value)} placeholder="Clave invitado" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" defaultValue={inputEmailEdit} onKeyUp={(e) => setInputEmailEdit(e.target.value)} placeholder="Email" />
                        </Form.Group>

                        <Button className='btn_buscar btn_right' type="submit" disabled={disableEditBtn} onClick={(handleSubmitEdit)}>
                            Guardar
                        </Button>
                        </>
                    ) :
                    (
                        <>
                            No se puede editar el usuario.
                        </>
                    )
                }
                    
                
                </Modal.Body>
        </Modal>

        <Modal show={showDelete} onHide={handleCloseDelete}>
            <Modal.Header closeButton>
            <Modal.Title>Eliminar invitado</Modal.Title>
            </Modal.Header>
            <Modal.Body>¿Está seguro que desea eliminar este invitado?</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDelete}>
                Cancelar
            </Button>
            <Button variant="danger" onClick={() => handleDelete()} >
                Confirmar
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={showQr} onHide={handleCloseQr}> 
            <Modal.Header closeButton>
            <Modal.Title>QR invitado</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            { displayQr ? (
                    <>
                    <img src={displayQr} alt="QR invitado" className='QR_main' />
                    </>
                ):
                (
                    <>
                      No se cargó el código QR.
                    </>
                )
            }
            </Modal.Body>
            <Modal.Footer>
            <Button className='btn_buscar btn_right' onClick={handleCloseQr}>
                Cerrar
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={showPass} onHide={handleClosePass}> 
            <Modal.Header closeButton>
            <Modal.Title>Activar acceso</Modal.Title>
            </Modal.Header>
            <Modal.Body>¿Realmente desea marcar este acceso como utilizado?</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClosePass}>
                Cancelar
            </Button>
            <Button variant="danger" onClick={() => ConfirmAsPass()} >
                Confirmar
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={showResend} onHide={handleCloseResend}> 
            <Modal.Header closeButton>
            <Modal.Title>Reenviar email</Modal.Title>
            </Modal.Header>
            <Modal.Body>¿Realmente desea reenviar el acceso por email?</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseResend}>
                Cancelar
            </Button>
            <Button variant="danger" onClick={() => ConfirmResend()} >
                Confirmar
            </Button>
            </Modal.Footer>
        </Modal>
            
        </Container>
    );
}

export default TableInvitados;