import { useState, useEffect } from 'react';
import { Table, Form, Tab, Tabs, Row, Container, Col } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import { TiDelete } from "react-icons/ti";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import Alert from 'react-bootstrap/Alert';
import { FaRegFile } from "react-icons/fa6";

function TableEventos() {

    const [show, setShow]                                                   = useState(false);
    const [showDelete, setShowDelete]                                       = useState(false);
    const [showAdd, setShowAdd]                                             = useState(false);
    const [deleteId, setDeleteId]                                           = useState(false);
    const [items, setItems]                                                 = useState([]);
    const [showAlert, setShowAlert]                                         = useState(false);
    const [showAlertDelete, setShowAlertDelete]                             = useState(false);
    const [horarios, setHorarios]                                           = useState([]);
    const [statusBtnAdd, setStatusBtnAdd]                                   = useState(false);

    // ---------- nuevo eventos ------------
    const [selectVenues, setSelectVenues]                                   = useState([]);
    const [inputNombre, setInputNombre]                                     = useState("");
    const [inputVenue, setInputVenue]                                       = useState("");
    const [inputFecha, setInputFecha]                                       = useState("");
    const [inputHorario, setInputHorario]                                   = useState("");
    const [inputStatus, setInputStatus]                                     = useState("");
    const [inputRestriccion, setInputRestriccion]                           = useState("");
    const [inputHorariosEspecificos, setInputHorariosEspecificos]           = useState(1);
    const [inputCapacidad, setInputCapacidad]                               = useState("");
    const [image, setImage]                                                 = useState({ preview: '', data: '' })

    // ----- edit eventos -----------
    const [inputIdEdit, setInputIdEdit]                                     = useState("");
    const [inputNombreEdit, setInputNombreEdit]                             = useState("");
    const [inputVenueEdit, setInputVenueEdit]                               = useState("");
    const [inputFechaEdit, setInputFechaEdit]                               = useState("");
    const [inputHorarioEdit, setInputHorarioEdit]                           = useState("");
    const [inputStatusEdit, setInputStatusEdit]                             = useState("");
    const [inputRestriccionEdit, setInputRestriccionEdit]                   = useState("");
    const [inputHorariosEspecificosEdit, setInputHorariosEspecificosEdit]   = useState("");
    const [inputCapacidadEdit, setInputCapacidadEdit]                       = useState("");
    const [imageEdit, setImageEdit]                                         = useState({ preview: '', data: '' })

    // --------------------------- add horarios ----------------------------

    const [inputHorarioInicio, setInputHorarioInicio]                       = useState("");
    const [inputHorarioFin, setInputHorarioFin]                             = useState(0);
    const [inputCapacidadHorario, setInputCapacidadHorario]                 = useState("");
    const [showDeleteHorario, setShowDeleteHorario]                         = useState(false);
    const [deleteIdHorario, setDeleteIdHorario]                             = useState(false);


    const handleClose               = () => setShow(false);
    const handleCloseDelete         = () => setShowDelete(false);
    const handleCloseDeleteHorario   = () => setShowDeleteHorario(false);
    const handleCloseAdd            = () => setShowAdd(false);

    const handleFileChange = (e) => {
        
        // if (e.target.files && e.target.files[0]) {
        //     console.log(e.target.files[0])
        //     if (e.target.files[0].size > 1 * 1000 * 1024) {
        //         console.log("File with maximum size of 1MB is allowed");
        //         return false;
        //     }

        //     // do other operation
        // }

        if (e.target.files[0]) {

                const img           = document.createElement('img');
                const selectedImage = e.target.files[0];
                const objectURL     = URL.createObjectURL(selectedImage);
                img.onload          = function handleLoad() {
                        
                    console.log(`Width: ${img.width}, Height: ${img.height}`);
            
                    if (img.width == 640 && img.height == 320) {
                        const img2 = {
                            preview: URL.createObjectURL(e.target.files[0]),
                            data: e.target.files[0],
                        }
                        setImage(img2)
                    }
                    else{
                        alert("Error, La imagen debe de medir 640px x 320px.")
                        e.target.value = null;
                    }
                
                    URL.revokeObjectURL(objectURL);
                };
            
                img.src = objectURL;
                //document.body.appendChild(img);                
        }
        
    }

    const handleFileChangeEdit = (e) => {
        
        const img = {
          preview: URL.createObjectURL(e.target.files[0]),
          data: e.target.files[0],
        }
        setImageEdit(img)
    }

    const handleShow = (item_id) => {
        
        setShow(true);
    
        let ud          = sessionStorage.getItem('userData');
        let userData    = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 5000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
        
            instance.get('api/get-evento/'+item_id)
            .then(response => {

                // var today       = new Date(response.data.data[0].fecha_evento_format);
                // var year        = today.getFullYear();
                // var mes         = today.getMonth()+1;
                // mes             = mes.toString().padStart(2, '0')
                // var dia         = today.getDate();
                // dia             = dia.toString().padStart(2, '0')
                // // var fecha_ok    = year+"-"+mes+"-"+dia;

                setInputIdEdit(response.data.data[0].id)
                setInputNombreEdit(response.data.data[0].nombre)
                setInputFechaEdit(response.data.data[0].fecha_evento_format)
                setInputHorarioEdit(response.data.data[0].horario)
                setInputVenueEdit(response.data.data[0].id_venue)
                setInputStatusEdit(response.data.data[0].status)
                setInputRestriccionEdit(response.data.data[0].restriccion_horario)
                setInputHorariosEspecificosEdit(response.data.data[0].horarios_especificos)
                setInputCapacidadEdit(response.data.data[0].capacidad)
            })

            instance.get('api/get-horarios/'+item_id)
            .then(response => {
                setHorarios(response.data.data)
            })

    }

    const handleShowAdd = async () => {
        setShowAdd(true);
    }

    const handleShowDelete = (item_id) => {
        setShowDelete(true);
        setDeleteId(item_id)
    }

    const handleShowDeleteHorario = (item_id) => {
        setShowDeleteHorario(true);
        setDeleteIdHorario(item_id)
    }

    const  handleDelete = () => {
        deleteEvento(deleteId)
    }

    const  handleDeleteHorario = () => {
        deleteHorario(deleteIdHorario)
    }

    const getData= async () => {
            
        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 10000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
        
        //trackPromise(
            await instance.get('api/get-eventos')
            .then(response => {
                setItems(response.data)
                console.log(response.data)
            })

            await instance.get('api/get-venues')
            .then(response2 => {
                setSelectVenues(response2.data)
            })
        //)
    }

    useEffect(()=>{
        getData()
    },[])

    const deleteEvento=(id)=>{
            
        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 5000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
        
        trackPromise(
            instance.delete('api/delete-evento/'+id)
            .then(response => {
                //console.log(response.status)
                if(response.status === 200){
                    setShowDelete(false);
                    setDeleteId("")
                    setShowAlertDelete(true)
                    getData()
                }
            })
        );
    }

    const deleteHorario=(id)=>{
            
        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 5000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
        
        trackPromise(
            instance.delete('api/delete-horario/'+id)
            .then(response => {
                //console.log(response.status)
                if(response.status === 200){

                    instance.get('api/get-horarios/'+inputIdEdit)
                    .then(response => {
                        setHorarios(response.data.data)
                    });

                    alert("Eliminado con éxito")
                }
            })
        );
    }

    const handleSubmitEdit = async (event) => {

        event.preventDefault();
    
        if( inputNombreEdit && inputFechaEdit && inputHorarioEdit && inputVenueEdit && inputRestriccionEdit>=0 && inputStatusEdit>=0 && inputHorariosEspecificosEdit>=0){
          
            // ----------------------- nuevo endpoint con attach de photo

            let ud            = sessionStorage.getItem('userData');
            let userData      = JSON.parse(ud);

            const formData = new FormData();
            formData.append('_method', 'post');
            formData.append("nombre", inputNombreEdit)
            formData.append("fecha_evento", inputFechaEdit)
            formData.append("horario", inputHorarioEdit)
            formData.append("id_venue", inputVenueEdit)
            formData.append("restriccion", inputRestriccionEdit)
            formData.append("horarios_especificos", inputHorariosEspecificosEdit)
            formData.append("capacidad", inputCapacidadEdit)
            formData.append("status", inputStatusEdit)
            formData.append("file", imageEdit.data)
            
            console.log(imageEdit)


            fetch("api/update-evento/"+inputIdEdit, {
                method: "POST",
                body: formData,
                //headers: {'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer '+userData.access_token}
                headers: {'Authorization': 'Bearer '+userData.access_token}
              })
              .then(response => {
                console.log(response.status)
                if(response.status === 200){
                    setShow(false)
                    setTimeout(console.log("wait..."), 5000); 
                    getData()
                  }
              })
              .catch(function (error) {
                console.log(error)
                    
              });


        //   var data_send = {
        //     "nombre": inputNombreEdit,
        //     "fecha_evento": inputFechaEdit,
        //     "horario": inputHorarioEdit,
        //     "id_venue": inputVenueEdit,
        //     "restriccion": inputRestriccionEdit,
        //     "horarios_especificos": inputHorariosEspecificosEdit,
        //     "capacidad": inputCapacidadEdit,
        //     "status": inputStatusEdit,
        //   }

        //   console.log(data_send);









    
        //   const instance = axios.create({
        //           baseURL: '',
        //           timeout: 5000,
        //           headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
        //           });
            
            // instance.put('/api/update-evento/'+inputIdEdit, data_send)
            // .then(response => {
            //     //console.log(response)
            //     if(response.data.status === "200"){
            //         // setInputNombreEdit("");
            //         // setInputExistenciasEdit("");
            //         setShow(false)
            //         setShowAlert(true)
            //         getData()
            //     }
            // })
            // .catch(function (error) {
            //     if(error.response.status === "401"){
            //     console.log(error)
                
            //     }
            // });
    
    
        }
        else{
          //return error
          alert("Faltan datos"+inputNombreEdit +" "+ inputFechaEdit +" "+ inputHorarioEdit +" "+ inputVenueEdit +" "+ inputRestriccionEdit +" "+ inputStatusEdit +" "+ inputHorariosEspecificosEdit);
        }
        
    }

    const handleSubmit = async (event) =>{

        event.preventDefault();
        setStatusBtnAdd(true)
        if(inputNombre && inputFecha && inputHorario && inputVenue && image && inputStatus>=0 && inputRestriccion>=0 && inputHorariosEspecificos>=0){
            
              // ----------------------- nuevo endpoint con attach de photo

              let ud            = sessionStorage.getItem('userData');
              let userData      = JSON.parse(ud);

              const formData = new FormData();
              formData.append('_method', 'post');
              formData.append("nombre", inputNombre)
              formData.append("fecha_evento", inputFecha)
              formData.append("horario", inputHorario)
              formData.append("id_venue", inputVenue)
              formData.append("status", inputStatus)
              formData.append("restriccion", inputRestriccion)
              if(inputRestriccion>0)
                formData.append("horarios_especificos", 0)
              else
                formData.append("horarios_especificos", inputHorariosEspecificos)
                
              formData.append("capacidad", inputCapacidad)
              formData.append("file", image.data)

              fetch("api/add-evento", {
                method: "POST",
                body: formData,
                // headers: {'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer '+userData.access_token}
                headers: {'Authorization': 'Bearer '+userData.access_token}
              })
              .then(response => {
                console.log(response.data)
                if(response.status === 200){
                    setInputNombre("");
                    setInputFecha("");
                    setInputHorario("");
                    setInputVenue("");
                    setInputStatus("");
                    setInputRestriccion("");
                    setInputHorariosEspecificos("");
                    setShowAdd(false)
                    setImage("")
                    setShowAlert(true)
                    setTimeout(console.log("wait..."), 5000); 
                    getData()
                    setStatusBtnAdd(false)
                  }
              })
              .catch(function (error) {
                console.log(error)
                setStatusBtnAdd(false)
              });

        }
        else
            alert("Error #3435, [ Faltan datos ]"+inputNombre + inputFecha + inputHorario + inputVenue + inputStatus);
    }

    const handleSubmitHorario = (event) =>{

        event.preventDefault();

        if(inputHorarioInicio && inputHorarioFin && inputCapacidadHorario){
            
            
            var data_send = {
                "horario_inicio": inputHorarioInicio,
                "horario_fin": inputHorarioFin,
                "capacidad": inputCapacidadHorario,
                "id_evento": inputIdEdit,
              }

        
              let ud = sessionStorage.getItem('userData');
              let userData = JSON.parse(ud);
        
              const instance = axios.create({
                      baseURL: '',
                      timeout: 5000,
                      headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                      });
                
              instance.post('api/add-horario', data_send)
              .then(response => {
    
                  if(response.data.status === "200"){
                    setInputHorarioInicio("");
                    setInputHorarioFin("");
                    setInputCapacidadHorario("");

                    instance.get('api/get-horarios/'+inputIdEdit)
                    .then(response => {
                        setHorarios(response.data.data)
                    });

                    alert("Se guardo exitosamente")
                  }
              })
              .catch(function (error) {
               
                    if(error.response.status === "401"){
                      console.log("se venció el token, please update it")
                      window.location.replace("./refresh-token");
                      
                    }
              });

        }
        else
            alert("Error #3435, [ Faltan datos ]"+inputHorarioInicio + inputHorarioFin + inputCapacidadHorario);
    }

    const TableHorarios = ()=> {
        return (
            <>
                <Table striped bordered hover responsive  className='mt-4'>
                    <thead>
                        <tr>
                            <th>Entrada</th>
                            <th>Salida</th>
                            <th>Capacidad</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                            {
                                horarios && horarios.length>0 && horarios.map((item)=> {         
                                return(
                                        <>
                                            <tr>
                                                <td>{item.hora_inicial}</td>
                                                <td>{item.hora_fin}</td>
                                                <td>{item.capacidad}</td>
                                                <td><TiDelete onClick={() => handleShowDeleteHorario(item.id)} /></td>
                                            </tr>  
                                        </>
                                    )
                                }) 
                            }
                    </tbody>
                </Table>
                
                <Row>
                    <Col className='mt-3'>
                            <hr></hr>
                    </Col>
                </Row>
                <Row>
                    <Col className='mt-1'>
                        <h4 className='titulo_soft'>Agregar nuevo horario</h4>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className='label_soft'>Horario inicio</Form.Label>
                            <Form.Select id="inp_horario_inicio" className='input_soft' aria-label="Default select example" value={inputHorarioInicio} onChange={(e) => setInputHorarioInicio(e.target.value)} >
                                <option>Seleccionar...</option>
                                <option value="00:00">00:00</option>
                                <option value="00:30">00:30</option>
                                <option value="01:00">01:00</option>
                                <option value="01:30">01:30</option>
                                <option value="02:00">02:00</option>
                                <option value="02:30">02:30</option>
                                <option value="03:00">03:00</option>
                                <option value="03:30">03:30</option>
                                <option value="04:00">04:00</option>
                                <option value="04:30">04:30</option>
                                <option value="05:00">05:00</option>
                                <option value="05:30">05:30</option>
                                <option value="06:00">06:00</option>
                                <option value="06:30">06:30</option>
                                <option value="07:00">07:00</option>
                                <option value="07:30">07:30</option>
                                <option value="08:00">08:00</option>
                                <option value="08:30">08:30</option>
                                <option value="09:00">09:00</option>
                                <option value="09:30">09:30</option>
                                <option value="10:00">10:00</option>
                                <option value="10:30">10:30</option>
                                <option value="11:00">11:00</option>
                                <option value="11:30">11:30</option>
                                <option value="12:00">12:00</option>
                                <option value="12:30">12:30</option>
                                <option value="13:00">13:00</option>
                                <option value="13:30">13:30</option>
                                <option value="14:00">14:00</option>
                                <option value="14:30">14:30</option>
                                <option value="15:00">15:00</option>
                                <option value="15:30">15:30</option>
                                <option value="16:00">16:00</option>
                                <option value="16:30">16:30</option>
                                <option value="17:00">17:00</option>
                                <option value="17:30">17:30</option>
                                <option value="18:00">18:00</option>
                                <option value="18:30">18:30</option>
                                <option value="19:00">19:00</option>
                                <option value="19:30">19:30</option>
                                <option value="20:00">20:00</option>
                                <option value="20:30">20:30</option>
                                <option value="21:00">21:00</option>
                                <option value="21:30">21:30</option>
                                <option value="22:00">22:00</option>
                                <option value="22:30">22:30</option>
                                <option value="23:00">23:00</option>
                                <option value="23:30">23:30</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className='label_soft'>Horario fin</Form.Label>
                            <Form.Select id="inp_horario_fin" className='input_soft' aria-label="Seleccionar..." value={inputHorarioFin} onChange={(e) => setInputHorarioFin(e.target.value)}>
                            <option>Seleccionar...</option>
                                <option value="00:00">00:00</option>
                                <option value="00:30">00:30</option>
                                <option value="01:00">01:00</option>
                                <option value="01:30">01:30</option>
                                <option value="02:00">02:00</option>
                                <option value="02:30">02:30</option>
                                <option value="03:00">03:00</option>
                                <option value="03:30">03:30</option>
                                <option value="04:00">04:00</option>
                                <option value="04:30">04:30</option>
                                <option value="05:00">05:00</option>
                                <option value="05:30">05:30</option>
                                <option value="06:00">06:00</option>
                                <option value="06:30">06:30</option>
                                <option value="07:00">07:00</option>
                                <option value="07:30">07:30</option>
                                <option value="08:00">08:00</option>
                                <option value="08:30">08:30</option>
                                <option value="09:00">09:00</option>
                                <option value="09:30">09:30</option>
                                <option value="10:00">10:00</option>
                                <option value="10:30">10:30</option>
                                <option value="11:00">11:00</option>
                                <option value="11:30">11:30</option>
                                <option value="12:00">12:00</option>
                                <option value="12:30">12:30</option>
                                <option value="13:00">13:00</option>
                                <option value="13:30">13:30</option>
                                <option value="14:00">14:00</option>
                                <option value="14:30">14:30</option>
                                <option value="15:00">15:00</option>
                                <option value="15:30">15:30</option>
                                <option value="16:00">16:00</option>
                                <option value="16:30">16:30</option>
                                <option value="17:00">17:00</option>
                                <option value="17:30">17:30</option>
                                <option value="18:00">18:00</option>
                                <option value="18:30">18:30</option>
                                <option value="19:00">19:00</option>
                                <option value="19:30">19:30</option>
                                <option value="20:00">20:00</option>
                                <option value="20:30">20:30</option>
                                <option value="21:00">21:00</option>
                                <option value="21:30">21:30</option>
                                <option value="22:00">22:00</option>
                                <option value="22:30">22:30</option>
                                <option value="23:00">23:00</option>
                                <option value="23:30">23:30</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="inp_capacidad_horario2">
                            <Form.Label className='label_soft'>Capacidad</Form.Label>
                            <Form.Control id="inp_capacidad_horario" type="text" className='input_soft' defaultValue={inputCapacidadHorario} onChange={(e) => setInputCapacidadHorario(e.target.value)}  placeholder="Asistentes" required />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="d-grid gap-2">
                            <Button className='input_soft' onClick={(handleSubmitHorario)} variant="danger">Guardar</Button>
                        </div>
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <>
        <Row className='mb-4'>
            <Col><h1 className="mt-4">Eventos</h1></Col>
            <Col className='iconos_div'><FaRegFile className="btn-add-nuevo" onClick={() => handleShowAdd()}  /></Col>
        </Row>

        <Alert className="mt-4" key="success" variant="success" show={showAlert} onClose={() => setShowAlert(false)} dismissible>
            El evento se guardó exitosamente
        </Alert>

        <Alert className="mt-4" key="danger" variant="danger" show={showAlertDelete} onClose={() => setShowAlertDelete(false)} dismissible>
            El evento se eliminó exitosamente
        </Alert>

        <Table striped bordered hover responsive>
            <thead>
                <tr>
                    <th className='encabezado_tabla'>#</th>
                    <th className='encabezado_tabla'>Nombre</th>
                    <th className='encabezado_tabla'>Fecha</th>
                    <th className='encabezado_tabla'>Horario</th>
                    <th className='encabezado_tabla'>Venue</th>
                    <th className='encabezado_tabla'>Capacidad</th>
                    <th className='encabezado_tabla'>Restricción horario</th>
                    <th className='encabezado_tabla'>Horarios específicos</th>
                    <th className='encabezado_tabla'>Status</th>
                    <th className='encabezado_tabla'>Acciones</th>
                </tr>
            </thead>
            <tbody>
            {
                items && items.length>0 && items.map((item)=> {
                    
                    var txt_status          = ""
                    var txt_restriccion     = ""
                    var txt_horarios_esp    = ""

                    if(item.status === 1)
                        txt_status = "Activo"
                    else
                        txt_status = "Inactivo"

                    if(item.restriccion_horario === 1)
                        txt_restriccion = "Activo"
                    else
                        txt_restriccion = "Inactivo"

                    if(item.horarios_especificos === 1)
                        txt_horarios_esp = "Entrada por horarios"
                    else
                        txt_horarios_esp = "Entrada general"

                    return (
                    <tr>
                        <td className='data_tabla'>{item.ide}</td>
                        <td className='data_tabla'>{item.nombre}</td>
                        <td className='data_tabla'>{item.fecha_evento_format}</td>
                        <td className='data_tabla'>{item.horario_format}</td>
                        <td className='data_tabla'>{item.nombre_venue}</td>
                        <td className='data_tabla'>{item.capacidad}</td>
                        <td className='data_tabla'>{txt_restriccion}</td>
                        <td className='data_tabla'>{txt_horarios_esp}</td>
                        <td className='data_tabla'>{txt_status}</td>
                        <td className='data_tabla'><FaEdit className='icono_listado' onClick={() => handleShow(item.ide)}  /> <TiDelete className='icono_listado' onClick={() => handleShowDelete(item.ide)} /> </td>
                    </tr>
                    )
                }
                )
            }
            </tbody>
        </Table>

        <Modal show={showAdd} onHide={handleCloseAdd}>
                <Modal.Header className='none-div' closeButton>
                <Modal.Title>Agregar Evento</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Container>
                        <Row>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label htmlFor="input_nombre">Nombre del evento</Form.Label>
                                <Form.Control 
                                id="input_nombre"
                                type="text" 
                                placeholder="Nombre del evento"
                                // defaultValue={}
                                onKeyUp={(e) => setInputNombre(e.target.value)}
                                required />
                            </Form.Group>
                        </Row>

                        <Row>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label htmlFor="input_nombre">Venue</Form.Label>
                                <Form.Select aria-label="Seleccione..." onChange={(e) => setInputVenue(e.target.value)}>
                                    <option>Seleccione...</option>
                                    {
                                        selectVenues.map((item)=> 
                                            (<option value={item.id}>{item.nombre_venue}</option>))
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group controlId="formFile" className="mb-3">
                                <Form.Label>Foto del evento (640px x 320px)</Form.Label>
                                <Form.Control type="file" onChange={handleFileChange}
                                required />
                            </Form.Group>
                        </Row>
                        
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Fecha</Form.Label>
                                    <Form.Control type="date" onChange={(e) => setInputFecha(e.target.value)} placeholder="fecha evento" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Horario</Form.Label>
                                    <Form.Control type="text" onKeyUp={(e) => setInputHorario(e.target.value)} placeholder="20:00" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label htmlFor="input_nombre">Restricción de horario</Form.Label>
                                    <Form.Select aria-label="Seleccione..." onChange={(e) => setInputRestriccion(e.target.value)}>
                                        <option>Seleccione...</option>
                                        <option value="1">Activo</option>
                                        <option value="0">Inactivo</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                           
                            {   inputRestriccion< 1 &&
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label htmlFor="input_nombre">Horarios específicos</Form.Label>
                                        <Form.Select aria-label="Seleccione..." onChange={(e) => {  setInputHorariosEspecificos(e.target.value)  } }>
                                            <option>Seleccione...</option>
                                            <option value="0">Entrada general</option>
                                            <option value="1">Entrada por horarios</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            }
                            
                        </Row>


                    {
                        inputRestriccion === "1" || inputHorariosEspecificos == "0"  ? 
                            (
                                <Row>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >
                                        <Form.Label>Capacidad del evento</Form.Label>
                                        <Form.Control type="text" defaultValue={inputCapacidad} onKeyUp={(e) => setInputCapacidad(e.target.value)} placeholder="personas por evento" />
                                    </Form.Group>
                                </Row>
                            ) : (<></>)
                        }

                        <Row>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label htmlFor="input_nombre">Status</Form.Label>
                                <Form.Select aria-label="Seleccione..." onChange={(e) => setInputStatus(e.target.value)}>
                                    <option>Seleccione...</option>
                                    <option value="1">Activo</option>
                                    <option value="0">Inactivo</option>
                                </Form.Select>
                            </Form.Group>
                        </Row>

                        <Row>
                            <Col>
                                <Button className='btn_buscar btn_right' disabled={statusBtnAdd} type="submit" onClick={(handleSubmit)}>
                                    Guardar
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
        </Modal>

        <Modal show={show} size="lg" onHide={handleClose}>
            <Modal.Header className='none-div' closeButton>
            <Modal.Title>Editar Evento</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Tabs defaultActiveKey="formulario" id="uncontrolled-tab-example" className="mb-3">

                    <Tab eventKey="formulario" className='mt-3' title="Información del evento">

                        <Container>
                            <Row>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label htmlFor="input_nombre">Nombre del evento</Form.Label>
                                    <Form.Control 
                                    id="input_nombre"
                                    type="text" 
                                    placeholder="Nombre del evento"
                                    defaultValue={inputNombreEdit}
                                    onKeyUp={(e) => setInputNombreEdit(e.target.value)}
                                    required />
                                </Form.Group>
                            </Row>
                            
                            <Row>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label htmlFor="input_nombre">Venue</Form.Label>
                                    <Form.Select aria-label="Seleccione..." value={inputVenueEdit} onChange={(e) => setInputVenueEdit(e.target.value)}>
                                        <option>Seleccione...</option>
                                        {
                                            selectVenues.map((item)=> 
                                                (<option value={item.id}>{item.nombre_venue}</option>))
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Row>

                            <Row>
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label>Foto del evento</Form.Label>
                                    <Form.Control type="file" onChange={handleFileChangeEdit}
                                    required />
                                </Form.Group>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="exampleForm.Fecha">
                                        <Form.Label>Fecha</Form.Label>
                                        <Form.Control type="date" defaultValue={inputFechaEdit} onChange={(e) => setInputFechaEdit(e.target.value)} placeholder="fecha evento" />
                                    </Form.Group>
                                 </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="exampleForm.Horario">
                                        <Form.Label>Horario</Form.Label>
                                        <Form.Control type="text" defaultValue={inputHorarioEdit} onKeyUp={(e) => setInputHorarioEdit(e.target.value)} placeholder="20:00" />
                                    </Form.Group>
                                </Col>                            
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicEmailRest">
                                        <Form.Label htmlFor="input_restriccion">Restriccion de horario</Form.Label>
                                        <Form.Select aria-label="Seleccione..." value={inputRestriccionEdit} onChange={(e) => setInputRestriccionEdit(e.target.value)}>
                                            <option>Seleccione...</option>
                                            <option value="1">Activo</option>
                                            <option value="0">Inactivo</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                {   inputRestriccionEdit< 1 &&
                                        <Col>
                                            <Form.Group className="mb-3" controlId="formBasicEmailHor">
                                                <Form.Label htmlFor="input_tipo">Horarios específicos</Form.Label>
                                                <Form.Select aria-label="Seleccione..." value={inputHorariosEspecificosEdit} onChange={(e) => setInputHorariosEspecificosEdit(e.target.value)}>
                                                    <option>Seleccione...</option>
                                                    <option value="1">Entrada por horarios</option>
                                                    <option value="0">Entrada general</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                }
                            </Row>
                            {
                                inputRestriccionEdit === "1" || inputHorariosEspecificosEdit == "0"  ? 
                                (
                                    <Row>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >
                                            <Form.Label>Capacidad del evento</Form.Label>
                                            <Form.Control type="text" defaultValue={inputCapacidadEdit} onKeyUp={(e) => setInputCapacidadEdit(e.target.value)} placeholder="personas por evento" />
                                        </Form.Group>
                                    </Row>
                                ) : (<></>)
                            }

                            <Row>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label htmlFor="input_nombre">Status</Form.Label>
                                    <Form.Select aria-label="Seleccione..." value={inputStatusEdit} onChange={(e) => setInputStatusEdit(e.target.value)}>
                                        <option>Seleccione...</option>
                                        <option value="1">Activo</option>
                                        <option value="0">Inactivo</option>
                                    </Form.Select>
                                </Form.Group>
                            </Row>     
                            <Row>
                                <Col>
                                    <Button className='btn_buscar btn_right' type="submit" onClick={(handleSubmitEdit)}>
                                        Editar
                                    </Button>
                                </Col>     
                            </Row>

                        </Container>

                    </Tab>
                    <Tab eventKey="horarios" title="Horarios espefificos">

                            {
                                inputHorariosEspecificosEdit>0 ? ( 
                                         <TableHorarios />
                                ):(
                                    <>
                                        <Row>
                                            <div className='text-center mt-4'> 
                                                Esta funcionalidad está desactivada, por favor actívela desde la opción horarios específicos
                                            </div>
                                        </Row>
                                    </>
                                )
                            }
                           
                    </Tab>

                </Tabs>

            </Modal.Body>
        </Modal>

        <Modal show={showDelete} onHide={handleCloseDelete}>
            <Modal.Header closeButton>
            <Modal.Title>Eliminar evento</Modal.Title>
            </Modal.Header>
            <Modal.Body>¿Está seguro que desea eliminar este evento?</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDelete}>
                Cancelar
            </Button>
            <Button variant="danger" onClick={() => handleDelete()} >
                Confirmar
            </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={showDeleteHorario} onHide={handleCloseDeleteHorario}>
            <Modal.Header closeButton>
            <Modal.Title>Eliminar horario</Modal.Title>
            </Modal.Header>
            <Modal.Body>¿Está seguro que desea eliminar este horario?</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDeleteHorario}>
                Cancelar
            </Button>
            <Button variant="danger" onClick={() => handleDeleteHorario()} >
                Confirmar
            </Button>
            </Modal.Footer>
        </Modal>
            
        </>
    );
}

export default TableEventos;