import Webcam from "react-webcam";
import "../generales.css";
import { useCallback, useRef, useState } from "react"; // import useCallback
import axios from "axios";
// import { useWindowSize } from "@uidotdev/usehooks";
import { FaPlay } from "react-icons/fa6";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FiCommand } from "react-icons/fi";
// css


const WebCamContainer = () =>{

    const [resultado, setResultado] = useState("");
    const [show, setShow] = useState(false);
    const [cortinaShow, setCortinaShow] = useState(false);
    const [playShow, setPlayShow] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const webcamRef = useRef(null);
    const [imgSrc, setImgSrc] = useState(null);

   

    const retake = () => {
      setCortinaShow(false);
      setPlayShow(true)
      setImgSrc(null);
      setShow(false)
    };

    const SaveImage = (formData) =>{

            let ud = sessionStorage.getItem('userData');
            let userData = JSON.parse(ud);
    
            const instance = axios.create({
                    baseURL: '',
                    timeout: 90000,
                    headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                    });
                    
            instance.post('/api/post-photo/', formData)
            .then(response => {
                console.log(response.data);
                console.log(JSON.stringify(response))
                if(response.status === 200){
                    // alert(response.data.confidence)
                    setResultado(response.data.mensaje)
                    // console.log(response.data.image.original.resource);
                    handleShow()
                } 
                else{
                    alert("NO se encontró algun objeto");
                }
              
          })
          .catch(error => console.log(error));
    
    }

    // create a capture function
    const capture = useCallback(() => {

      setCortinaShow(true);
      setPlayShow(false)
      console.log()
      const imageSrc = webcamRef.current.getScreenshot();
      //console.log(imageSrc)
      setImgSrc(imageSrc);
      const formData = new FormData();
      formData.append("file", imageSrc);
      SaveImage(formData);

    }, [webcamRef]);


    const videoConstraints = {
      facingMode:  "environment" 
        // facingMode: "user", 
    };

    const BtnPlay = () => (
        <FaPlay className="btn_play" onClick={capture} />
    )

    const Cortina = () => (
      <div className="cortina">
       <FiCommand className="loading-icon" />
       <br />
       <button className="btn_retake" onClick={retake}>Retake photo</button>
      </div>
   )

      return (
        <>
       { cortinaShow ? <Cortina /> : null }
        <div className="container_wc">
          {imgSrc ? (
            <img src={imgSrc} alt="webcam" />
          ) : (
            <Webcam 
              height={1000}
              width={1000}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              screenshotFormat="image/jpeg"
              screenshotQuality={1}
              forceScreenshotSourceSize="true"
              videoConstraints={videoConstraints}
              ref={webcamRef} /> 
          )}
          
            {/* <button onClick={capture}>Capture photo</button> */}
            {/* <FaPlay className="btn_play" onClick={capture} /> */}
            { playShow ? <BtnPlay /> : null }
    

        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Reconocimiento facial</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                {resultado}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={retake}>
              Cerrar
            </Button>
            
          </Modal.Footer>
        </Modal>
        
        </>
      );
    
}


export default  WebCamContainer;