import Menu from "../components/menu/Menu";
import {Container} from 'react-bootstrap';
import Verify from "../components/verify/Verify"
import {Navigate} from 'react-router-dom';
import Dasboard from "../components/dashboard/Dashboard";

function Welcome() {

  if(!sessionStorage.getItem('userData')){
    return <Navigate to="/"  />;
  }
  else{
      Verify()
  }
  let ud = sessionStorage.getItem('userData');
  let userData = JSON.parse(ud);

  if(userData.jerarquia === 2 || userData.jerarquia === 1 ){
      return(
          <>
              <Menu />
              <Container>
                  <Dasboard />
              </Container>
          </>
      )
  }
  else{
      return(<>
          <Menu />
          <Container>
             <h1 className="mt-4">No tiene permiso para ver este recurso</h1>
          </Container>
     </>)
  }


};

export default Welcome;