import { useEffect, useState } from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { DefaultizedPieValueType } from '@mui/x-charts/models';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import axios from 'axios';
import './dashboard.css'

const Dashboard = () => {

    const [dataPieReal, setDataPieReal]       = useState();

    const getData= async () => {
  
        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
          baseURL: '',
          timeout: 10000,
          headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
        });
            
          await instance.get('api/get-data-pie')
          .then(response => {
              
              const colores = [];
              colores.push("#0088FE", "#00C49F", "#FFBB28", "#FF8042");
              var nuevo = JSON.parse(JSON.stringify(response.data))
          
              Object.keys(nuevo).forEach(function(element, index) {
                  nuevo[element].color  = colores[index];
              });
              setDataPieReal(nuevo)
          })

    }

    useEffect(()=>{
          getData()
    },[])

    const DrawPie = () => {
    
        const sizing = {
            // margin: { right: 5 },
            width: 600,
            height: 300,
            legend: { hidden: false },
        };

        const data = dataPieReal;
    
        const TOTAL = data.map((item) => parseInt(item.value)).reduce((a, b) => a + b, 0);
                    
        const getArcLabel = (params: DefaultizedPieValueType) => {
            const percent =  parseInt(params.value) / TOTAL;
            return `${(percent * 100).toFixed(0)}%`;
        };
    
        return (
            <>
            { 
                dataPieReal && (
                <PieChart
                        series={[
                          {
                            outerRadius: 80,
                            data,
                            arcLabel: getArcLabel,
                          },
                        ]}
                        sx={{
                          [`& .${pieArcLabelClasses.root}`]: {
                            fill: 'white',
                            fontSize: 12,
                          },
                        }}
                        {...sizing}
                      />
                )
            }
            </>
        )
    }

  return (<>
            <Container>
                <h1 className="mt-4">Dashboard</h1>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                */}
                <Row className='mt-5'> 
                    <Col className='centrar text-center'>
                    <h4>Eventos por venue</h4>
                    { 
                        dataPieReal && (
                            <DrawPie />
                        )
                    }
                    </Col>
                    <Col className='centrar text-center'>
                    <h4 >Tasa de asistencia total</h4>
                    { 
                        dataPieReal && (
                            <DrawPie />
                        )
                    }
                    </Col>
                </Row> 

            </Container>
           
  </>);
};

export default Dashboard;