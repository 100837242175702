import { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import "./lector-fisico.css";
import { SpinningCircles } from 'react-loading-icons'

function LectorFisico() {

    let ud = sessionStorage.getItem('userData');
    let userData = JSON.parse(ud);

    const instance = axios.create({
        baseURL: '',
        timeout: 5000,
        headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
        });


    const [checkedId, setCheckedId]                             = useState(false);
    const [showValido, setShowValido]                           = useState(false);
    const [showError, setShowError]                             = useState(false);
    const [showErrorHorario, setShowErrorHorario]               = useState(false);
    const [fechaAcceso, setFechaAcceso]                         = useState("");


    const [inputSearch, setInputSearch]                         = useState("");
    const [showLoading, setShowLoading]                         = useState(false);

    const handleCloseValido = () => {
        window.location.reload();
    }

    const handleCloseError = () => {
        window.location.reload();
    }

    const handleCloseErrorHorario = () => {
        window.location.reload();
    }

    const handleValido = (data) => {
       
        instance.get('/api/update-code/'+data)
        .then(response => {
            if(response.data.status === "200"){
               setShowValido(false)
               alert("Acceso correcto")
               window.location.reload();
            }
            else{
                alert("El QR no es válido");
                window.location.reload();
            }
        })
        .catch(function (error) {
            if(error.response.status === "401"){
            console.log(error)
            
            }
        });
    };

    const compareDates = (d1) => {
        let date1               = new Date();
        let fecha_e2             = new Date(d1);
        let fecha_e3             = new Date(d1);
        
        addHours(fecha_e2, 2);
        substractHours(fecha_e3, 2);

        if (date1 >= fecha_e3 && date1 <=fecha_e2) {
            return true;
        } else {
            return false;
        }
    };

    function addHours(date, hours) {
        const hoursToAdd = hours * 60 * 60 * 1000;
        date.setTime(date.getTime() + hoursToAdd);
        return date;
    }
    
    function substractHours(date, hours) {
        const hoursToAdd = hours * 60 * 60 * 1000;
        date.setTime(date.getTime() - hoursToAdd);
        return date;
    }

    const onFormSubmit = e => {
        e.preventDefault();
        
            setShowLoading(true)
            
            var data = inputSearch.replace(/[^\w\s]/gi, '')
          
            instance.get('/api/get-status/'+data)
            .then(response => {
                console.log(response)
                setShowLoading(false)
                if(response.data.status === "200"){
                   
                    var estatus            = response.data.data[0].status;
                    var restriccion         = response.data.data[0].restriccion_horario;
                    var horarios_esp        = response.data.data[0].horarios_especificos;
                    var id_evento           = response.data.data[0].id_evento;
                    
                   if(estatus<1){
    
                    if(restriccion<1)
                        setShowValido(true);
                    else{
    
                        //aqui va la restriccion de horario ....
                        if(horarios_esp>0){
    
                            // ----- traer los horarios de ese evento ----------
                            instance.get('/api/get-horarios/'+id_evento)
                            .then(responsex => {
                                
                                if(responsex.data.status === "200"){
    
                                    var real_data   = responsex.data.data;
                                    var total       = 0;
                                    real_data.forEach(element => {
    
                                        var datetime_inicial    = element.fecha_evento_format+"T"+ element.hora_inicial;
                                        var datetime_final      = element.fecha_evento_format+"T"+ element.hora_fin
    
                                        var from                = new Date(datetime_inicial); 
                                        var to                  = new Date(datetime_final);
                                        var check               = new Date();
    
                                        if(check >= from && check <= to){
                                            total ++
                                        }
    
                                    });
    
                                    if(total>0){
                                        console.log("Acceso correcto");
                                        setShowValido(true)
                                        
                                    }
                                    else{
                                        console.log("Acceso incorrecto");
                                        setShowErrorHorario(true);
                                    }
    
    
                                }
                            })
                            .catch(function (error) {
                                if(error.responsex.status === "401"){
                                console.log(error)
                                
                                }
                            });
    
                            // -------- end horarios de ese evento ----------
    
                        }
                        else{
                            var horario_e       = response.data.data[0].horario;
                            var fecha_evento_e2 = response.data.data[0].fecha_evento_format+" "+horario_e;
    
                            if(compareDates(fecha_evento_e2)){
                                console.log("acceso correcto");
                                setShowValido(true);
                            }
                            else{
                                console.log("Acceso incorrecto");
                                //  ------- despliega el modal de incorrecto ¿mostrando las horas correctas?
                                setShowErrorHorario(true);
                            }
                        }
                        // ------ end restriccion de horario
                        
    
    
                    }
                   }
                   else{
                    setShowError(true);
                    var fa = response.data.data[0].fecha_acceso;
                    const myTime = new Date(fa);
                    myTime.toLocaleString("es-MX", {timeZone: "America/Mexico_City"})
                    //console.log(myTime)
                    setFechaAcceso(myTime.toString());
                   }
                }
                else{
                    alert("El QR no es válido");
                    window.location.reload();
                }
            })
            .catch(function (error) {
                if(error.response.status === "401"){
                console.log(error)
                
                }
            });
    
            setCheckedId(data)
    
        



    }
    
    return ( 
        <>
        {showLoading && (
                        <div className="div_loading">
                            Loading...
                            <br />
                            <SpinningCircles fill="#00ff85" />
                        </div>
            ) 
        }

        <Modal show={showValido} onHide={handleCloseValido}>
                <Modal.Header closeButton>
                <Modal.Title>Información de scanner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Bienvenido {checkedId}, tiene un acceso válido.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseValido}>
                        Cancelar
                    </Button>
                    <Button variant="success" onClick={() => handleValido(checkedId)} >
                        Confirmar acceso
                    </Button>
                </Modal.Footer>
        </Modal>


        <Modal show={showError} onHide={handleCloseError}>
            <Modal.Header closeButton>
            <Modal.Title>Información de scanner</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                El ID {checkedId} ya fue utilizado el día {fechaAcceso}.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => handleCloseError()} >
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={showErrorHorario} onHide={handleCloseErrorHorario}>
            <Modal.Header closeButton>
            <Modal.Title>Horario no válido</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Solo se puede acceder al evento dos horas antes.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => handleCloseErrorHorario()} >
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>

        <form onSubmit={onFormSubmit}>
            <Row className='mt-4'>
                <Col lg="10"><Form.Control size="lg" type="text" placeholder="Clave de invitado" onChange={(e) => setInputSearch(e.target.value)} /></Col>
                <Col className='d-grid'><Button size="lg" className='btn_buscar' type="submit">Buscar</Button></Col>
            </Row>
        </form>
        </>
    );
}

export default LectorFisico;