import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import LogoMando from "../../assets/images/logo-mando.png";
import "./menu.css";

function Menu() {

  let ud              = sessionStorage.getItem('userData');
  let userData        = JSON.parse(ud);
  var ju              = userData.jerarquia

  switch (ju) {
    case 2:
      return (
        <Navbar collapseOnSelect expand="lg" bg="dark" data-bs-theme="dark" className="bg-body-tertiary">
          <Container>
            <Navbar.Brand href="../"><img className="logo-top" src={LogoMando} alt="Mando Access" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="../lector">Lector</Nav.Link>
                <Nav.Link href="../lector-fisico">Lector Físico</Nav.Link>
                <Nav.Link href="../venues">Venues</Nav.Link>
                <Nav.Link href="../eventos">Eventos</Nav.Link>
                <Nav.Link href="../invitados">Invitados</Nav.Link>
              </Nav>
              <Nav>
                <NavDropdown title="Logout" id="collapsible-nav-dropdown">
                  <NavDropdown.Item href="../usuarios">Usuarios</NavDropdown.Item>
                  <NavDropdown.Item href="../my-account">Mi cuenta</NavDropdown.Item>
                  <NavDropdown.Item href="../logout">Logout</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      );

    case 1:
        return (
          <Navbar collapseOnSelect expand="lg"  bg="dark" data-bs-theme="dark" className="bg-body-tertiary">
            <Container>
            <Navbar.Brand href="/"><img className="logo-top" src={LogoMando} alt="Mando Access" /></Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link href="lector">Lector</Nav.Link>
                  <Nav.Link href="lector-fisico">Lector Físico</Nav.Link>
                </Nav>
                <Nav>
                  <NavDropdown title="Logout" id="collapsible-nav-dropdown">
                    <NavDropdown.Item href="my-account">Mi cuenta</NavDropdown.Item>
                    <NavDropdown.Item href="logout">Logout</NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        );

      case 0:
        return (
          <Navbar collapseOnSelect expand="lg"  bg="dark" data-bs-theme="dark" className="bg-body-tertiary">
            <Container>
            <Navbar.Brand href="/"><img className="logo-top" src={LogoMando} alt="Mando Access" /></Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link href="lector">Atender usuario</Nav.Link>
                </Nav>
                <Nav>
                  <NavDropdown title="Logout" id="collapsible-nav-dropdown">
                    <NavDropdown.Item href="my-account">Mi cuenta</NavDropdown.Item>
                    <NavDropdown.Item href="logout">Logout</NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        );
      
      default:
      break;
  }

  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/">Dominga</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="blogs">Blogs</Nav.Link>
            <Nav.Link href="contact">Contact</Nav.Link>
            <Nav.Link href="lector">Lector</Nav.Link>
            <Nav.Link href="productos">Productos</Nav.Link>
            
          </Nav>
          <Nav>
            <NavDropdown title="Logout" id="collapsible-nav-dropdown">
              <NavDropdown.Item href="usuarios">Usuarios</NavDropdown.Item>
              <NavDropdown.Item href="my-account">Mi cuenta</NavDropdown.Item>
              <NavDropdown.Item href="logout">Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Menu;