import { useState, useEffect } from 'react';
import { Table, Form, Row, Col } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';
import { TiDelete } from "react-icons/ti";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import Alert from 'react-bootstrap/Alert';
import {AdvancedMarker, APIProvider, Map} from '@vis.gl/react-google-maps';
import { FaRegFile } from "react-icons/fa6";

function TableVenues() {

    const [show, setShow]                                       = useState(false);
    const [showDelete, setShowDelete]                           = useState(false);
    const [showAdd, setShowAdd]                                 = useState(false);
    const [deleteId, setDeleteId]                               = useState(false);
    const [items, setItems]                                     = useState([]);
    
    const [inputNombre, setInputNombre]                         = useState("");
    const [inputDireccion, setInputDireccion]                   = useState("");

    const [inputIdEdit, setInputIdEdit]                         = useState("");
    const [inputNombreEdit, setInputNombreEdit]                 = useState("");
    const [inputLatitudEdit, setInputLatitudEdit]               = useState("");
    const [inputLongitudEdit, setInputLongitudEdit]             = useState("");

    const [showAlert, setShowAlert]                             = useState(false);
    const [showAlertDelete, setShowAlertDelete]                 = useState(false);
    const [inputPosition, setInputPosition]                     = useState({ lat: 10.99835602, lng: 77.01502627}); 
    const [inputPositionEdit, setInputPositionEdit]             = useState(); 
    const [inputLat, setInputLat]                               = useState("");
    const [inputLng, setInputLng]                               = useState("");
    const [inputDireccionEdit, setInputDireccionEdit]           = useState("");
    const [zoom, setZoom]                                       = useState(16);
    const [statusBtnAdd, setStatusBtnAdd]                       = useState(false);

    const handleClose = () => setShow(false);
    const handleCloseDelete = () => setShowDelete(false);
    const handleCloseAdd = () => setShowAdd(false);


    //------------------------- mapas -------------------------
    //const position = {lat: 53.54992, lng: 10.00678};
      
    const handleShow = (item_id) => {
        setShow(true);
    
        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 5000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
        
            instance.get('api/get-venue/'+item_id)
            .then(response => {
                setInputIdEdit(response.data.data[0].id)
                setInputNombreEdit(response.data.data[0].nombre_venue)
                setInputLatitudEdit(response.data.data[0].latitud)
                setInputLongitudEdit(response.data.data[0].longitud)
                setInputPositionEdit({ lat: parseFloat(response.data.data[0].latitud), lng: parseFloat(response.data.data[0].longitud) })
                //setInputPositionEdit({ lat: 100.2, lng: -99.2 })
            })

    }

    const handleShowAdd = () => {
        setShowAdd(true);
    }

    const handleShowDelete = (item_id) => {
        setShowDelete(true);
        setDeleteId(item_id)
    }

    const  handleDelete = () => {
        deleteVenue(deleteId)
    }

    const getData= async () => {
            
        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 5000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
        
        //trackPromise(
            await instance.get('api/get-venues')
            .then(response => {
                setItems(response.data)
            })
        //)
    }

    useEffect(()=>{
        getData()
    },[])


    const deleteVenue=(id)=>{
            
        let ud = sessionStorage.getItem('userData');
        let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 5000,
                headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
        
        trackPromise(
            instance.delete('api/delete-venue/'+id)
            .then(response => {
                //console.log(response.status)
                if(response.status === 200){
                    setShowDelete(false);
                    setDeleteId("")
                    setShowAlertDelete(true)
                    getData()
                }
            })
        );
    }

    const handleSubmitEdit = async (event) => {

        event.preventDefault();
        console.log(inputNombreEdit + inputLatitudEdit + inputLongitudEdit)
    
        if( inputNombreEdit && inputLatitudEdit && inputLongitudEdit){
          
          var data_send = {
            "nombre": inputNombreEdit,
            "latitud": inputLatitudEdit,
            "longitud": inputLongitudEdit,
          }
    
          let ud = sessionStorage.getItem('userData');
          let userData = JSON.parse(ud);
    
          const instance = axios.create({
                  baseURL: '',
                  timeout: 5000,
                  headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                  });
            
            instance.put('/api/update-venue/'+inputIdEdit, data_send)
            .then(response => {
                //console.log(response)
                if(response.data.status === "200"){
                    // setInputNombreEdit("");
                    // setInputExistenciasEdit("");
                    setShow(false)
                    setShowAlert(true)
                    getData()
                }
            })
            .catch(function (error) {
                if(error.response.status === "401"){
                console.log(error)
                
                }
            });
    
    
        }
        else{
          //return error
          alert("Faltan datos"+inputNombreEdit);
        }
        
    }

        const handleChangePosition = async (marker) => {
            setInputLat(marker.latLng.lat())
            setInputLng(marker.latLng.lng())
            //console.log(marker.latLng.lat(), marker.latLng.lng(),  'lat and lng from marker')
        }

        const handleChangePositionEdit = async (marker) => {
            setInputLatitudEdit(marker.latLng.lat())
            setInputLongitudEdit(marker.latLng.lng())
            //console.log(marker.latLng.lat(), marker.latLng.lng(),  'lat and lng from marker')
        }

      const handleSubmit = async (event) =>{

        event.preventDefault();
        setStatusBtnAdd(true)
        if(inputNombre && inputLat && inputLng){
            
            var data_send = {
                "nombre_venue": inputNombre,
                "latitud": inputLat,
                "longitud": inputLng
              }
        
              let ud = sessionStorage.getItem('userData');
              let userData = JSON.parse(ud);
        
              const instance = axios.create({
                      baseURL: '',
                      timeout: 5000,
                      headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                      });
                
              instance.post('api/add-venue', data_send)
              .then(response => {
    
                  if(response.data.status === "200"){
                    setInputNombre("");
                    setInputLat("");
                    setInputLng("");
                    setShowAdd(false)
                    setShowAlert(true)
                    getData()
                    setStatusBtnAdd(false)
                  }
              })
              .catch(function (error) {
               
                    if(error.response.status === "401"){
                      console.log("se venció el token, please update it")
                      window.location.replace("./refresh-token");
                      
                    }
              });

        }
        else{
            setStatusBtnAdd(false)
            alert("faltan datos");
        }
            
      }

      const handleAddress = async (event) =>{
        //alert("activado")
        event.preventDefault();
        let api_key = "AIzaSyC5QIUGS6tkK-RY3NvybzE-F6NzcNWPtoo";
        var cadena = "https://maps.googleapis.com/maps/api/geocode/json?address="+encodeURI(inputDireccion)+"&key="+api_key;

        // ----------------------------------------
        // let ud = sessionStorage.getItem('userData');
        // let userData = JSON.parse(ud);

        const instance = axios.create({
                baseURL: '',
                timeout: 5000,
                // headers: {'content-type': 'application/json', 'Authorization': 'Bearer '+userData.access_token}
                });
        
        //trackPromise(
            await instance.get(cadena)
            .then(response => {
                //console.log(response.data.results[0].geometry.location);
                setInputPosition({ lat: response.data.results[0].geometry.location.lat, lng: response.data.results[0].geometry.location.lng})
                setInputLat(response.data.results[0].geometry.location.lat)
                setInputLng(response.data.results[0].geometry.location.lng)
                // setItems(response.data)
            })

        // ----------------------------------------
      }

      const handleZoomChange = (event) => {
        setZoom(event.detail.zoom);
      };

      const handleAddressEdit = async (event) =>{
        //alert("activado")
        event.preventDefault();
        let api_key = "AIzaSyC5QIUGS6tkK-RY3NvybzE-F6NzcNWPtoo";
        var cadena = "https://maps.googleapis.com/maps/api/geocode/json?address="+encodeURI(inputDireccionEdit)+"&key="+api_key;

        // ----------------------------------------
        const instance = axios.create({
                baseURL: '',
                timeout: 5000,
                });
        
            await instance.get(cadena)
            .then(response => {
                //console.log(response.data.results[0].geometry.location);
                setInputPositionEdit({ lat: response.data.results[0].geometry.location.lat, lng: response.data.results[0].geometry.location.lng})
                setInputLatitudEdit(response.data.results[0].geometry.location.lat)
                setInputLongitudEdit(response.data.results[0].geometry.location.lng)
                // setItems(response.data)
            })
        // ----------------------------------------
      }

  return (
    <>
    <Row className='mb-4'>
        <Col><h1 className="mt-4">Venues</h1></Col>
        <Col className='iconos_div'><FaRegFile className="btn-add-nuevo" onClick={() => handleShowAdd()}  /></Col>
    </Row>

    <Alert className="mt-4" key="success" variant="success" show={showAlert} onClose={() => setShowAlert(false)} dismissible>
        El venue se guardó exitosamente
    </Alert>

    <Alert className="mt-4" key="danger" variant="danger" show={showAlertDelete} onClose={() => setShowAlertDelete(false)} dismissible>
        El venue se eliminó exitosamente
    </Alert>


    <Table striped bordered hover responsive>
      <thead>
        <tr>
            <th className='encabezado_tabla'>#</th>
            <th className='encabezado_tabla'>Nombre</th>
            <th className='encabezado_tabla'>Latitud</th>
            <th className='encabezado_tabla'>Longitud</th>
            <th className='encabezado_tabla'>Acciones</th>
        </tr>
      </thead>
      <tbody>
      {
        items && items.length>0 && items.map((item)=>
            <tr>
                <td className='data_tabla'>{item.id}</td>
                <td className='data_tabla'>{item.nombre_venue}</td>
                <td className='data_tabla'>{item.latitud}</td>
                <td className='data_tabla'>{item.longitud}</td>
                <td className='data_tabla'><FaEdit className='icono_listado' onClick={() => handleShow(item.id)}  /> <TiDelete className='icono_listado' onClick={() => handleShowDelete(item.id)} /> </td>
            </tr>
        )
      }
      </tbody>
    </Table>

    <Modal show={showAdd} onHide={handleCloseAdd}>
        <Modal.Header closeButton>
        <Modal.Title>Agregar Venue</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label htmlFor="input_nombre">Nombre del lugar</Form.Label>
                <Form.Control 
                id="input_nombre"
                type="text" 
                placeholder="Nombre del lugar"
                // defaultValue={}
                onKeyUp={(e) => setInputNombre(e.target.value)}
                required />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Row>
                    <Col>
                        <Form.Label htmlFor="input_nombre">Dirección del lugar</Form.Label>
                        <Form.Control 
                        id="input_direccion"
                        type="text" 
                        placeholder="Dirección del lugar"
                        // defaultValue={}
                        onKeyUp={(e) => setInputDireccion(e.target.value)}
                        required />
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        <Button onClick={(handleAddress)} className='btn_right' variant="success">Obtener coordenadas</Button>
                    </Col>
                </Row>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label htmlFor="input_almacen">Mapa</Form.Label>
                    
                    <div style={{ height: '300px', width: '100%' }}>
                        <APIProvider apiKey={'AIzaSyC5QIUGS6tkK-RY3NvybzE-F6NzcNWPtoo'}>
                            <Map center={inputPosition} defaultZoom={16} controlled={false} onBoundsChanged={(event) => { setInputPosition( event.detail.center)  }} mapId="DEMO_MAP_ID">
                                <AdvancedMarker draggable={true} onDragEnd={(handleChangePosition)} position={inputPosition} />
                            </Map>
                        </APIProvider>
                    </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
            <Row>
                <Col>
                    <Form.Label htmlFor="input_nombre">Latitud</Form.Label>
                    <Form.Control 
                    id="input_nombre"
                    type="text" 
                    defaultValue={inputLat}
                    placeholder="Nombre del lugar"
                    readOnly={true}
                    required />
                </Col>
                <Col>
                    <Form.Label htmlFor="input_nombre">Longitud</Form.Label>
                    <Form.Control 
                    id="input_nombre"
                    type="text" 
                    defaultValue={inputLng}
                    placeholder="Nombre del lugar"
                    readOnly={true}
                    required />
                </Col>
            </Row>
                
            </Form.Group>

            <Button className='btn_buscar btn_right' type="submit" disabled={statusBtnAdd} onClick={(handleSubmit)}>
                Guardar
            </Button>
        
        </Modal.Body>
    </Modal>


        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Editar venue</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label htmlFor="input_nombre">Nombre del lugar</Form.Label>
                    <Form.Control 
                    id="input_nombre"
                    type="text" 
                    placeholder="Nombre del lugar"
                    defaultValue={inputNombreEdit}
                    onKeyUp={(e) => setInputNombreEdit(e.target.value)}
                    required />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label htmlFor="input_nombre">Dirección del lugar</Form.Label>
                    <Form.Control 
                    id="input_direccion"
                    type="text" 
                    placeholder="Dirección del lugar"
                    // defaultValue={}
                    onKeyUp={(e) => setInputDireccionEdit(e.target.value)}
                    required /><Button onClick={(handleAddressEdit)} variant="success">Obtener coordenadas</Button>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label htmlFor="input_almacen">Mapa</Form.Label>
                       
                        <div style={{ height: '300px', width: '100%' }}>
                            <APIProvider apiKey={'AIzaSyC5QIUGS6tkK-RY3NvybzE-F6NzcNWPtoo'}>
                                <Map center={inputPositionEdit} zoom={zoom} defaultZoom={16} controlled={false} onBoundsChanged={(event) => { setInputPositionEdit( event.detail.center)  }}  onZoomChanged={handleZoomChange} mapId="DEMO_MAP_ID">
                                    <AdvancedMarker draggable={true} onDragEnd={(handleChangePositionEdit)} position={inputPositionEdit} />
                                </Map>
                            </APIProvider>
                        </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                <Row>
                    <Col>
                        <Form.Label htmlFor="input_nombre">Latitud</Form.Label>
                        <Form.Control 
                        id="input_nombre"
                        type="text" 
                        defaultValue={inputLatitudEdit}
                        onChange={(e) => setInputLatitudEdit(e.target.value)}
                        placeholder="Nombre del lugar"
                        readOnly={true}
                        required />
                    </Col>
                    <Col>
                        <Form.Label htmlFor="input_nombre">Longitud</Form.Label>
                        <Form.Control 
                        id="input_nombre"
                        type="text" 
                        defaultValue={inputLongitudEdit}
                        onChange={(e) => setInputLongitudEdit(e.target.value)}
                        placeholder="Nombre del lugar"
                        readOnly={true}
                        required />
                    </Col>
                </Row>
                    
                </Form.Group>
                <Row>
                    <Col>
                        <Button className='btn_buscar btn_right' type="submit" onClick={(handleSubmitEdit)}>
                            Guardar
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
        

        <Modal show={showDelete} onHide={handleCloseDelete}>
            <Modal.Header closeButton>
            <Modal.Title>Eliminar venue</Modal.Title>
            </Modal.Header>
            <Modal.Body>¿Está seguro que desea eliminar este venue?</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDelete}>
                Cancelar
            </Button>
            <Button variant="danger" onClick={() => handleDelete()} >
                Confirmar
            </Button>
            </Modal.Footer>
        </Modal>
        
    </>
  );
}

export default TableVenues;